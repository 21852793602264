import { type PropsWithChildren, type ReactNode } from 'react';
import { Button, type ButtonProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { CircularProgress } from './circular-progress';

type Props = PropsWithChildren<{
  icon?: ReactNode;
  loading?: boolean;
  iconClassName?: string;
}> &
  ButtonProps;

export function PrimaryButton({ loading, icon, type = 'submit', iconClassName, ...rest }: Props) {
  const { children, isDisabled, className, ...props } = rest;

  return (
    <Button
      {...props}
      isDisabled={isDisabled || loading}
      type={type}
      className={twMerge(
        'flex h-[44px] w-full items-center justify-center gap-[5px] rounded-[4px] bg-primary text-[14px] text-white hover:opacity-70 disabled:opacity-70',
        `${className}`,
      )}
    >
      {children}
      {loading && <CircularProgress className={twMerge('h-[12px] w-[12px] text-white', iconClassName)} />}
      {!loading && icon}
    </Button>
  );
}
